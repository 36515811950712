// tslint:disable:max-line-length
import { sharedTranslations } from '../../libs/translations/de';

export class Deutsch {
  static translations = {
    sharedTranslations,
    popups: {
      deactivateUser: {
        title: 'Deaktivierung bestätigen',
        text: 'Sind Sie sicher, dass Sie Ihr Profil deaktivieren möchten? Alle Ihre Angaben werden dann gelöscht. Um wieder mit IAMconnected zu arbeiten, müssen Sie einen neuen Account erstellen.',
      },
      backToMainMenuNewOrg: {
        title: 'Zurück zum Hauptmenü?',
        text: 'Möchten Sie zum Hauptmenü zurückkehren? (die Unternehmensdaten bleiben erhalten) oder möchten Sie eine andere Firma nachschlagen',
        confirm: 'Zurück zum Hauptmenü',
        cancel: 'eine andere Firma nachschlagen',
      },
    },
    buttons: {
      send: 'Senden',
      delete: 'Löschen',
      reset: 'Zurücksetzen',
      cancel: 'Annullieren',
      cancelRequest: 'Annullieren Anfrage',
      ok: 'Ok',
      submit: 'Einreichen',
      update: 'Aktualiseren',
      change: 'Ändern',
      create: 'Erstellen',
      register: 'Account erstellen',
      activate: 'Aktivieren',
      deactivate: 'Deaktivieren',
      save: 'Speichern',
      login: 'Einloggen',
      logout: 'Abmelden',
      close: 'Schließen',
      back: 'Zurück',
      next: 'Weiter',
      postponeRequest: 'Später beenden',
      toHome: 'Zur Startseite',
      toLogin: 'Zur Login-Seite',
      resetToken: 'Token zurücksetzen',
      editPersonalInformation: 'Persönliche Daten bearbeiten',
      changePassword: 'Passwort ändern',
      signUpWithOrganization: 'Beim Unternehmen anmelden',
      coupleWithOrganization: 'Verbinde mich mit diesem Unternehmen',
      homeLogin: 'Direkt in IAMconnected einloggen',
      homeRegister: 'Erstellen Sie jetzt Ihren eigenen IAMconnected-Account',
      addOrganization: 'Neues Unternehmen hinzufügen',
      accept: 'Akzeptieren',
      deny: 'Weigern',
      expandAll: 'Alle erweitern',
      collapseAll: 'Alles zusammenbrechen',
      search: 'Suche',
      refreshData: 'Daten aktualisieren',
      changeRequest: 'Änderung anfordern',
      validateData: 'Daten validieren',
      requestNewOrganization: 'Neue Organisation beantragen',
      identifyAgain: 'Identifizieren Sie erneut',
      resendIdentify: 'Einladung erneut versenden',
      clickHere: 'Klicke hier',
      yes: 'Ja',
      noEdit: 'Nein, Daten ändern',
      cancelOpenRequest: 'Annullieren offene Anfrage',
      detailOpenRequest: 'Details von Offene Anfrage',
      confirm: 'Bestätigen',
    },
    tooltips: {
      validated: 'Validiert',
      notValidated: 'In den letzten 3 Monaten nicht validiert',
      validationNotRequired: 'Validierung nicht erforderlich',
      validationByAdmin: 'Validierung durch den Hauptadministrator/-in'
    },
    icons: {
      simple: 'einfach',
      safe: 'sicher',
      personal: 'persönlich',
      free: 'kostenlos',
    },
    warningBox: {
      openRequestWarning: {
        content:
          'Sie können keine Änderung mehr beantragen, da Sie bereits eine offene Anfrage haben.',
        button: 'Offene Anfrage abbrechen',
      },
    },
    errorTexts: {
      errorSnackbar: {
        title: 'Ein unerwarteter Fehler ist aufgetreten.',
        mention: 'Klicken Sie hier, Sie können dies bei unserem melden',
        error: 'Errorcode: ',
      },
      authorization: {
        nonUniqueBusinessEmail: 'Diese Email-Addresse existiert bereits',
        missingBusinessInformation: 'Geschäftsinformationen fehlen',
        missingBusinessIdentification:
          'Die persönliche Identifikation der Organisation fehlt',
        missingBusinessPhone: 'Geschäftstelefonnummer fehlt',
        missingBusinessEmail: 'Geschäfts Email-Addresse fehlt',
      },
      general: {
        title: 'Fehler',
        badRequest:
          'Leider ist etwas schief gegangen. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
        forbidden: 'Sie haben keine Berechtigung',
        unknown: `Leider ist etwas schief gegangen. Versuchen Sie es noch einmal.`,
        internalServerError: `Leider ist etwas schief gegangen. Versuchen Sie es noch einmal.`,
        httpStatusCodes: {
          '0': 'Dienst nicht verfügbar.',
          '401':
            'Sie sind zurzeit nicht (mehr) eingeloggt. Sie werden zur Login-Seite weitergeleitet.',
          '403': 'Sie haben keine Berechtigung für diese Funktion.',
          '404': 'Eine Ressource wurde nicht gefunden.',
          '500': 'Etwas ist schief gegangen',
          '503': 'Dienst nicht verfügbar',
        },
        required: 'Bitte geben Sie einen Wert ein',
        validationFailed: 'Der Aktivierungscode ist nicht korrekt',
      },
      user: {
        operationNotAllowed: 'Diese Aktion ist nicht zulässig',
        userNameAlreadyExists: 'Benutzername existiert bereits',
        notAllowedToConnectToThisOrganization:
          'Nur Portbase-Mitarbeiter können sich mit dieser Organisation verbinden',
        updateCredentialsFailed:
          'Das neue Passwort darf nicht mit dem aktuellen Passwort identisch sein oder das aktuelle Passwort wurde falsch eingegeben. Bitte versuche es erneut.',
        invalidUsername: 'Der eingegebene Benutzername ist ungültig',
        usernameIsEmailAddress:
          'Der eingegebene Benutzername darf keine E-Mail-Adresse sein',
        emailNotVerified: 'E-Mail-Adresse wurde noch nicht bestätigt',
      },
      notAccepted:
        'Bitte akzeptieren Sie die Bedingungen, bevor Sie fortfahren',
      notAlphaNumeric: 'Der Wert ist nicht alphanumerisch',
      notNumeric: 'Nur numerische Werte sind erlaubt',
      notEmail: 'Falsche Email-Addresse',
      notPhone: 'Wert ist keine korrekte Telefonnummer',
      notOldPassword: 'Wert ist kein korrektes altes Passwort',
      notPassword: 'Wert ist kein korrektes Passwort',
      notMatchPassword: 'Passwörter stimmen nicht überein',
      notMaxLength:
        'Der Wert darf höchstens {{ requiredLength }} Zeichen betragen',
      notMinLength:
        'Der Wert muss aus mindestens {{ requiredLength }} Zeichen bestehen',
      noDataToDisplay: 'Keine Daten zum Anzeigen gefunden',
      noFilterResultsFound:
        'Es wurden keine Ergebnisse gefunden, die Ihren Kriterien entsprechen - bitte Filter ändern',
      noMainOrganization:
        'Eine Verbindung zu dieser Organisation ist jetzt nicht möglich',
      notFoundPage: {
        header: 'Nicht gefunden',
        body: 'Die angeforderte Seite konnte nicht gefunden werden. Wenn Sie glauben, dass dies ein Fehler ist, benachrichtigen Sie bitte Ihren Hauptadministrator.',
      },
      requiredFields: 'Mit * gekennzeichnete Felder müssen ausgefüllt werden.',
      cookiesDisabled: {
        title: 'Cookies sind deaktiviert',
        body: 'Diese Website benötigt funktionierende Cookies. Da Cookies in Ihrem Browser deaktiviert zu sein scheinen, können Sie IAMconnected nicht verwenden.',
      },
      organization: {
        cocIdAlreadyExists:
          'Ein Unternehmen mit dieser Handelskammer-Nummer / Duns Nummer existiert bereits.',
        operationNotAllowed: 'Diese Aktion ist nicht zulässig',
        alreadyExists: 'Organisation existiert bereits',
        unknownResource:
          'Daten nicht gefunden, bitte kontrolieren Sie die Eintrag.',
      },
      register: '',
      organizationType: {
        invalidType: 'Inaktiver Organisationstyp ausgewählt',
      },
      changeRequest: {
        unknownResource:
          'Daten nicht gefunden, bitte kontrolieren Sie die Eintrag.',
        invalidResourceSelected:
          'Daten nicht gefunden, bitte kontrolieren Sie die Eintrag.',
        alreadyExists: 'Änderungsanforderung existiert bereits',
        activeChangeRequest: 'Ihre Kontaktdaten werden derzeit geändert. Sie benötigen dies, um ein Unternehmen hinzufügen.',
        activeNewOrgRequest: 'Eine Anfrage ist bereits im Gange.',
        invalidAction: 'Ein unerwarteter Fehler ist aufgetreten.',
        invalidRepresentative:
          'Der bevollmächtigter Vertreter hat sich noch nicht identifiziert, dies muss zuerst erfolgen, bevor diese Änderung angefordert werden kann',
        activateNotAllowed:
          'Dieser Aktivierungscode kann nur vom neuen Hauptadministrator eingegeben werden',
      },
      emailNotVerified:
        'Validieren Sie zunächst Ihre E-Mail-Adresse, gehen Sie zu Profil und klicken Sie auf E-Mail-Adresse validieren.',
      validateByAdmin: 'Validierung durch den Hauptadministrator/-in',
      fileUpload: {
        choose: 'Datei auswählen',
        fileSize: {
          title: 'Datei ist zu groß',
          content: 'Die maximal zulässige Größe beträgt 50MB.',
          tooltip: 'Zum Beispiel ein Auszug aus der Handelskammer oder dem Handelsregister. <br>Zulässige Dateitypen sind pdf, jpg und jpeg.'

        },
        scan: {
          title: 'Problem beim Scannen der Datei',
          content: 'Versuchen Sie es mit einer anderen Datei. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unsere Vertriebsabteilung unter <u>sales@portbase.com</u>'
        },
        general: {
          title: 'Problem beim Hochladen',
          content: 'Versuchen Sie es mit einer anderen Datei. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unsere Vertriebsabteilung unter <u>sales@portbase.com</u>'
        }
      },
      bouncedEmail: {
        hardBounce: '{{email}} ist dauerhaft unerreichbar. <br>Bitte aktualisieren Sie die E-Mail-Adresse oder wenden Sie sich an den E-Mail-Provider oder den Helpdesk Ihrer Organisation, um dieses Problem zu lösen. Wenden Sie sich dann an den <a href="{{supportUrl}}" target="_blank">Customer Service</a> um {{email}} als wiederhergestellt zu vermerken. Dies ist notwendig, damit Sie wieder E-Mails empfangen können.',
        softBounce: '{{email}} ist derzeit nicht verfügbar.<br>Bitte überprüfen Sie die E-Mail-Adresse. Wenden Sie sich gegebenenfalls an den E-Mail-Anbieter Ihrer Organisation oder an den Helpdesk, wenn das Problem weiterhin besteht.',
        mailboxFull: 'Der Posteingang von {{email}} ist zu voll, um E-Mails zu empfangen. Bitte bitten Sie Ihren Bevollmächtigter, diesen Posteingang zu leeren.',
        authorizedRepr: {
          hardBounce: 'Der angegebene Bevollmächtigter Vertreter ist unter {{email}} dauerhaft nicht erreichbar. <br>Stornieren Sie diesen Anfrage und stellen Sie einen neuen Anfrage mit einer anderen E-Mail-Adresse des Bevollmächtigter Vertreter.',
          softBounce: 'Der angegebene Bevollmächtigter Vertreter kann unter {{email}} nicht erreicht werden. <br>Bitte klären Sie das Problem über den E-Mail-Provider oder den Helpdesk Ihrer eigenen Organisation. Oder stornieren Sie diesen Anfrage und stellen Sie einen neuen Anfrage mit anderen Kontaktangaben.',
          mailboxFull: 'Der Posteingang von {{email}} ist zu voll, um E-Mails zu empfangen. Bitte bitten Sie Ihren Bevollmächtigter, diesen Posteingang zu leeren.',
        }
      }
    },
    successTexts: {
      general: {
        title: 'Erfolgreich!',
        cancelActivationCode: 'Die Anfrage wurde gelöscht',
      },
      couple:
        'Erfolgreich! Sie erhalten eine E-Mail, wenn Sie vom Hauptadministrator akzeptiert wurden.',
      register:
        'Erfolgreich! Sie erhalten eine Email zum Aktivieren Ihres Accounts.',
      identification:
        'Erfolg! Sie erhalten eine E-Mail, um sich erneut zu identifizieren',
      updateUser: 'Erfolgreich! Ihr Profil wurde geändert.',
      coupleOrganization:
        'Sobald Ihr Hauptadministrator Ihnen die benötigten Rechte erteilt hat, sehen Sie auf Ihrer Startseite von IAMconnected die Dienste, zu denen Sie Zugang haben.',
      createOrganization:
        'Sie haben Ihre Organisation erfolgreich für IAMconnected registriert. Der bevöllmachtigter Vertreter wird per Email aufgefordert, sich auszuweisen. Nach seinem Zustimmung können Sie den Registrierungsprozess abschließen.',
      updateOrganization: 'Gelungen!',
      changeRequestSuccess:
        'Ihre Änderungsanfrage wurde angefordert. Sie erhalten in Kürze einen Aktivierungscode, um Ihre offene Anfrage zu aktivieren.',
      submitActivationCode: 'Die Organisation ist jetzt aktiv',
      cancelActivationCode: 'Die Anfrage wurde gelöscht',
      changeRequest: 'Erfolg! Anfrage wurde gestellt.',
      resendIdentification: 'Erfolg! Eine E-Mail wurde an der/die bevollmächtiger Vertreter/-in gesendet: ',
      verifyEmail: 'Erfolgreich! Validierungs-E-Mail gesendet'
    },
    hintTexts: {
      hintCocId: 'Die Handelskammer-Nummer muss keinen Ländercode enthalten',
      hintEmail: 'Beispiel: name@beispiel.com',
      hintPhone: 'Beispiel: +31612345678',
      hintPassword:
        'Muss mindestens 8 Zeichen, einen Kleinbuchstaben, einen Großbuchstaben, ein Sonderzeichen und eine Zahl enthalten',
      hintPortAuthorityId:
        'Geben Sie die Port Authority ID für das Unternehmen ein',
      hintOrganizationName:
        'Geben Sie die ersten Buchstaben des Unternehmens ein, mit dem Sie sich verbinden möchten.',
      hintPersonalIdentification:
        'Tragen Sie Ihre persönliche Unternehmens-Identifizierung ein. Sie erhalten sie von Ihrem Hauptadministrator.',
      establishmentNumber: 'Geben Sie hier die Standortnummer ein',
    },
    countries: {
      belgium: 'Belgien',
      bulgaria: 'Bulgarien',
      croatia: 'Kroatien',
      cyprus: 'Zypern',
      czechrepublic: 'Tschechische Republik',
      germany: 'Deutschland',
      denmark: 'Dänemark',
      estonia: 'Estland',
      finland: 'Finnland',
      france: 'Frankreich',
      greatbritain: 'Großbritannien',
      greece: 'Griechenland',
      hungary: 'Ungarn',
      ireland: 'Irland',
      italy: 'Italien',
      lithuania: 'Litauen',
      luxembourg: 'Luxemburg',
      latvia: 'Lettland',
      netherlands: 'Niederlande',
      poland: 'Polen',
      portugal: 'Portugal',
      romania: 'Rumänien',
      slovenia: 'Slowenien',
      slovakia: 'Slowakei',
      spain: 'Spanien',
      sweden: 'Schweden',
    },
    groups: {
      all: 'Alle Mitarbeiter',
      members: 'Mitarbeiter',
      admins: 'Hauptadministratoren',
      usermanagers: 'Nutzerrechteverwalter',
      suspended: 'Suspendiert',
      pending: 'Verbindungsanfrage',
      changeRequest: 'Änderungsanforderung',
    },
    groupStatus: {
      members: 'Mitarbeiter',
      admins: 'Hauptadministrator',
      usermanagers: 'Nutzerrechteverwalter',
      suspended: 'Suspendiert',
      pending: 'Anstehend',
    },
    organizationStatus: {
      new: 'Neu',
      active: 'Aktiv',
      inactive: 'Inaktiv',
      refused: 'Abgelehnt',
    },
    snackbar: {
      form: {
        created: 'Gelungen!',
        updated: 'Erfolgreich!',
        deleted: 'Erfolgreich!',
        success: 'Erfolgreich!',
        error: 'Fehlgeschlagen!',
      },
    },
    help: {
      title: 'Unterstützung',
      q1: {
        title: 'Ich habe mein Passwort vergessen, was nun?',
        answer: {
          step1: 'Schritt 1: Gehen Sie zur ',
          link1: 'Login-Seite',
          step2:
            'Schritt 2: Klicken Sie auf "Hilfe beim Anmelden benötigt" und dann auf "Passwort vergessen".',
          step3:
            'Schritt 3: Geben Sie Ihre Email-Adresse oder Ihren Nutzernamen ein (diesen finden Sie in der Aktivierungsmail, die Sie beim Erstellen Ihres Accounts bekommen haben).',
          step4:
            'Schritt 4: Klicken Sie auf „Per Email zurücksetzen“. Sie erhalten eine Email mit einem Link, über den Sie Ihr Passwort neu einrichten können.',
        },
      },
      q2: {
        title: 'Ich habe meinen Nutzernamen vergessen, was mache ich jetzt?',
        answer: {
          p1: 'Sie finden Ihren Nutzernamen in der Aktivierungsmail, die Sie beim Erstellen Ihres Accounts bekommen haben.',
          p2: 'Können Sie Ihren Nutzernamen nicht finden? Dann nehmen Sie Kontakt zum auf.',
          link1: 'IAMconnected Service Desk',
        },
      },
      q3: {
        title: 'Fallen bei der Verwendung eines IAMconnected-Kontos Kosten an?',
        answer:
          'Das Erstellen eines IAMconnected-Accounts ist für jeden kostenlos.',
      },
      q4: {
        title: 'Zu welchen Diensten habe ich Zugang?',
        answer:
          'Nachdem Sie einen eigenen IAMconnected-Account erstellt haben, können Sie sich einloggen. Auf der Startseite verbinden Sie sich dann unter „Unternehmensverwaltung“ mit Ihrem Unternehmen. So bekommen Sie Zugang zu den Diensten, mit denen Ihr Unternehmen Absprachen getroffen hat.',
      },
    },
    contact: {
      title: 'Kontakt',
      content:
        'Wenn Sie Unterstützung bei der Nutzung von IAMconnected brauchen, erreichen Sie uns unter:',
      email: 'support@iamconnected.eu',
      emailSubject: 'Kontaktanfrage über die IAMconnected-Website',
      phone: '+31 88 – 625 25 25 (zwischen 8 und 18 Uhr)',
      emergency:
        'Außerhalb der Bürozeiten sind wir unter folgender Notfallnummer erreichbar: +316 518 844 56.',
    },
    cookieBanner: {
      content:
        'Abgesehen davon, dass wir Cookies wirklich mögen, sind sie auch für das Funktionieren unserer Website notwendig. <br />Wir verwenden funktionale und analytische Cookies. ',
      readmore: 'Mehr Erklärung.',
    },
    home: {
      title: 'Willkommen',
      titleLoggedIn: 'Anwendungen',
      subTitleLoggedin: 'Ihre aktuellen Dienstleistungen',
      subTitle:
        'Ihr Zugang zu digitalen Diensten in Hafen und Logistik\neinfach, sicher, individuell, kostenlos',
      notCoupled: {
          title: 'Verbinden Sie Ihr Konto mit einer Organisation, um Zugang auf Dienste zu erhalten.',
          description: 'Ihr Konto ist noch nicht mit einer Organisation verbunden.',
          l1: 'Verwendet Ihre Organisation bereits IAMconnected? Verbinden Sie Ihr Konto mit Ihrer Organisation.',
          l2: 'Verwendet Ihre Organisation IAMconnected noch nicht? Lassen Sie Ihre Organisation von einem/einer Hauptadministrator/-in registrieren.',
          buttonRegister: 'Registrieren Sie eine neue Organisation',
          buttonLink: 'Verbinden zur bestehenden Organisation'
      },
      introduction: {
        id: {
          title: 'Ein einziger Zugang für mehrere Dienste',
          content:
            'IAMconnected ist Ihr Zugang zu mehreren digitalen Diensten in Hafen und Logistik. Sie müssen sich nicht mehr für jeden Dienst eigene Logins merken. Einmaliges Einloggen über IAMconnected reicht aus.',
        },
        usage: {
          title: 'Teilnehmen',
          content:
            'Bei Ihrem ersten Besuch von IAMconnected erstellen Sie einmalig eine persönliche Identität. Diese besteht aus einem selbst gewählten, individuellen Nutzernamen plus einem starken Passwort. Zur Bestätigung bekommen Sie eine Email.',
        },
        safety: {
          title: 'Sicherheit an erster Stelle',
          content:
            'Aus Sicherheitsgründen ist es wichtig, dass jede Person ihr eigenes, individuelles Konto erstellt. Verwenden Sie vorzugsweise eine E-Mail-Adresse mit dem Domainnamen Ihrer Organisation. Dadurch wird deutlich, dass Sie sich im Namen Ihres Unternehmens anmelden.',
        },
        start: {
          title: 'Das erste Mal auf dieser Startseite',
          content:
            'Dies ist die Startseite von IAMconnected. Hier finden Sie die digitalen Dienste, zu denen Sie Zugang haben. Ein Klick auf die gewünschte Kachel und Sie können sofort loslegen. Nutzen Sie mehrere Dienste? Dann können Sie auf dieser Seite bequem zwischen ihnen wechseln.',
        },
        initial: {
          title: 'Das erste Mal auf dieser Startseite',
          content:
            'Sehen Sie die gewünschten digitalen Dienste noch nicht auf Ihrer Startseite? Dann können Sie das unter „Unternehmensverwaltung“ ändern. Dort verbinden Sie Ihren persönlichen IAMconnected-Account mit dem Unternehmen, für das Sie arbeiten. Der Hauptadministrator gewährt Ihnen dann die benötigten Rechte. Auf Ihrer Startseite sehen Sie dann automatisch die für Sie zugänglichen Dienste.',
        },
      },
      services: {
        title: 'Dienste',
        pendingService:
        {
          title: 'Um auf die Dienste Zugang zu können, muss Ihre Verbindungsanfrage genehmigt werden',
          description: 'Ihre Verbindungsanfrage wurde noch nicht genehmigt. Wenden Sie sich an einen Administrator Ihrer Organisation, um eine Genehmigung zu erhalten und über IAMconnected auf Dienste Zugang zu können.'
        },
        noServicesAvailable:
          'Für die ausgewählte Organisation sind derzeit keine Dienste aktiv.'
      },
      sectionRequestService: {
        title: 'Dienste anfordern',
        description: 'Portbase und andere Organisationen bieten Dienstleistungen für verschiedene Zielgruppen in der Logistikkette. Finden und abonnieren Sie Services, um Ihren Logistikprozess weiter zu verbessern!',
        titleNoService: 'Willkommen zu Portbase',
        serviceButton: 'Portbase Dienste',
        otherButton: 'Andere Dienste',
        noService: {
          title: 'Ihre Organisation hat noch keinen Zugang auf Dienste',
          description: 'Dies kann daran liegen, dass die Serviceanfrage noch bearbeitet wird oder vom Portbase noch nicht abgeschlossen wurde. Wenn kürzlich eine Dienstleistung vom Portbase angefordert wurde, überprüfen Sie zunächst, ob der/die Bevollmächtigter Vertreter/-in in der Organisation den Vertrag unterzeichnet und zurückgesandt hat. Nachdem Portbase den unterzeichneten Vertrag erhalten hat, bereiten wir die gewünschte(n) Dienst(e) für die Nutzung vor. Dies dauert in der Regel einen Werktag. Wir halten den Antragsteller per E-Mail auf dem Laufenden.'
        }
      },
      notLoggedIn: 'Sie sind nicht eingeloggt.',
      notRegistered: 'Startklar?',
      privacy: 'Datenschutzerklärung und Allgemeine Geschäftsbedingungen'
    },
    register: {
      title: 'Account erstellen',
      subTitle:
        'Erstellen Sie hier Ihr IAMconnected-Konto im Namen Ihres Unternehmens.',
    },
    checkedid: {
      intro: {
        title: 'CheckedID Überprüfung',
        introText: 'Im Rahmen der Registrierung müssen Sie sich als bevollmächtigter Vertreter identifizieren. Das ist ein wesentlicher Bestandteil des Sicherheitsaspekts in den Hafen Community. Es passiert auf die sicherste Art und Weise unter Wahrung Ihrer Privatsphäre.',
        content: 'Die Identifizierung gibt Drei Schritten.\n\nBitte schließen Sie den Identifizierungsprozess innerhalb von 14 Tagen ab. Der Code in dieser E-Mail ist nur für diese Anzahl an Tagen gültig.',
      },
      step1: {
        title: 'Schritt 1',
        introText: 'Installieren Sie die CheckedID-App über die regulären App-Stores für Android oder iOS',
        content: 'Die App benötigt Zugriff auf Ihre Kamera, um Fotos Ihres Ausweises und Ihrer Person zu erhalten. Sie werden aufgefordert die NFC-Funktion zu aktivieren, damit der Chip Ihres ID-Dokuments gelesen werden kann.',
      },
      step2: {
        title: 'Schritt 2',
        introText: 'Benutzen Sie der CheckedID-App den Einladungscode unten zu scannen oder geben Sie den Code ein;',
        content: 'Dieser Code ist 14 Tage lang gültig. Falls der Code abgelaufen ist, kann Ihr Hauptadministrator/-in in spe eine neue Identifikations-E-Mail senden.',
      },
      step3: {
        title: 'Schritt 3',
        introText: 'Gehen Sie der Identifikationsprozess durch in der CheckedID-App. Nach Genehmigung der Identifizierung wird Ihnen der Organisation Registrierung zur Prüfung zugesandt.',
        content: 'Weitere Informationen und Unterstützung finden Sie auf <a href="https://support.portbase.com/de/identifikation/">Support Page</a>.',
      }
    },
    myProfile: {
      title: 'Profil',
      verification: {
        title: 'Zusätzliche Bestätigung (MFA)',
        introText:
          'Eine zusätzliche Bestätigung erhöht die Kontosicherheit, wenn Sie sich bei IAMconnected und anderen von Ihnen verwendeten Diensten anmelden. Weitere Informationen finden Sie im',
        supportLink: 'Portbase-Supportseite.',
        secondIntroText:
          'Neues Smartphone? Registrieren Sie sich zuerst mit Ihrem alten Smartphone, gehen Sie zum Profil und wählen Sie dort einen MFA-Reset. Danach können Sie MFA für Ihr neues Smartphone neu konfigurieren.',
        secondTabIntroText:
          'Fälle, in denen Sie Ihr Google Authenticator-Token möglicherweise widerrufen möchten:',
        secondTabReason1: `Ihr Telefon ist verloren gegangen und Sie möchten verhindern, dass nicht autorisierte Benutzer auf Ihr Konto zugreifen`,
        secondTabReason2:
          'Sie möchten Google Authenticator auf einem anderen Telefon installieren',
        secondTabOutroText:
          'Möchten Sie Ihr vorhandenes Google Authenticator-Token widerrufen und/oder neu konfigurieren?',
        warningModalTitle: 'MFA aktivieren',
        warningModalContent:
          'Nach der Aktivierung kann MFA nicht mehr deaktiviert werden. Möchten Sie wirklich fortfahren?<br /><br /> <strong>Hinweis:</strong> Wenn Sie fortfahren, werden Sie automatisch abgemeldet. Danach können Sie sich nur wieder anmelden unter Verwendung des MFA Codes den Sie auf Ihrem Handy erhalten.',
        warningModalResetMFAContent:
          'Danach können Sie sich nur wieder anmelden unter Verwendung des MFA Codes den Sie auf Ihrem Handy erhalten oder mit einem Sicherheitsschlüssel einrichten.',
      },
      personalInformation: {
        title: 'Kontodetails',
      },
      identification: {
        title: 'Identifizierung',
        successMessage:
          'Der Status für die externe Identifizierung wird nach erfolgreichem Abschluss des regulären Identifizierungsprozesses erneut aktualisiert.',
        notIdentified:
          'Eine Einladung wurde an Ihre E-Mail-Adresse gesendet, mit der Bitte, sich zu identifizieren. Wenn Sie es nicht erhalten haben, klicken Sie auf die Taste unten, um es erneut zu senden.',
      },
      businessInformation: {
        title: 'Geschäftsinformationen',
        introText: `Diese Informationen gelten nur für Ihre aktuell ausgewählte Organisation <strong> {{name}} </strong>.`,
        changeRequest:
          'Sie haben eine ausstehende Anfrage zur Änderung Ihrer Unternehmensinformationen. <br />Dies muss vom Hauptadministrator genehmigt werden, wenn dies nicht mehr gewünscht ist ',
      },
      businessRoles: {
        title: 'Organisatorische Rollen',
        introText: `Sie erfüllen die folgenden Rolle(n) in Ihrer aktuellen Organisation.`,
        noRolesAvailable: `Dieser Organisation sind derzeit keine Rollen zugeordnet.`,
      },
      changePassword: {
        title: 'Passwort ändern',
      },
      formLabels: {
        name: 'Name',
        firstName: 'Vorname',
        lastName: 'Nachname',
        userName: 'Nutzername',
        primaryEmail: 'Geschäftliche-Email-Addresse',
        mobilePhone: 'Geschäftstelefonnummer',
        oldPassword: 'Altes Passwort',
        newPassword: 'Neues Passwort',
        confirmNewPassword: 'Neues Passwort bestätigen',
        dateOfBirth: 'Geburtsdatum',
        documentNumber: 'Dokumentnummer',
        validityOfDocument: 'Gültigkeit des Dokuments',
        identificationStatus: 'Identifikationsstatus',
      },
    },
    requiredFieldsDialog: {
      title: 'Die folgenden fehlen noch fur {{ name }}',
    },
    changeBusinessInformationDialog: {
      step1: 'Achtung!',
      step2: 'Änderung anfordern',
      requestButton: 'Änderung anfordern',
      warning:
        'Achtung! Änderungen müssen erst vom Hauptadministrator akzeptiert werden, bevor sie sichtbar werden.',
      success:
        'Der Antrag steht noch aus. Der Hauptadministrator Ihrer Organisation muss jetzt Ihre Änderungsanforderung genehmigen. Bis dahin können Sie die für Ihr Unternehmen bestimmten Dienste vorübergehend nicht nutzen.',
    },
    newUserDialog: {
      chooseRole: {
        title: 'Willkommen bei IAMconnected',
        introText: `Wir helfen Ihnen dabei, Ihr IAMconnected-Konto schnell und einfach einzurichten`,
        roleQuestion: `Welche Rolle spielen Sie in der Organisation?`,
        hint: 'Wählen Sie die Rollen aus, die Teil Ihrer Position sind',
      },
      chooseCoupleOrganization: {
        title: 'Suchorganisation'
      },
      chooseNewUser: {
        intro: 'Dein Account ist jetz nicht im Verbindung mit eine Organisation. <br /> Eine Verbindete Organisation ist erforderlich, um einen Dienst anzufragen und zu nutzen.',
        searchOrgButton: 'Finden und verbinden Sie sich.',
        newOrganization: 'Neue Organisation registrieren',
        orgRegistration: {
          p1: 'Sie können Ihre Organisation nicht finden? Bitte lassen Sie es zuerst von Ihrem',
          p2: 'Hauptadministrator',
          p3: 'und',
          p4: 'Bevollmächtigter/-in',
          p5: 'registrieren'
        },
        adminTooltip: 'Der/die Antragsteller/-in für die Registrierung einer Organisation wird automatisch Hauptadministrator dieser Organisation.<br> <br>Der/die Hauptadministrator/-in einer Organisation ist unter anderem für die Verwaltung dieser Organisation verantwortlich.',
        reprTooltip: 'Während der Registrierung einer Organisation wird der/die bevollmächtigter Vertreter/-in aufgefordert sich auszuweisen und die Registrierung zu genehmigen. <br><br>Der/die bevollmächtigter Vertreter/-in ist eine natürliche Person, die gemäß Auszug aus der Handelskammer für diese Organisation zeichnungsberechtigt ist.',
        step1: {
          title: 'Organisation',
          introText:
            'Welcher bereits registrierten Organisation gehören Sie an?',
          pcsText:
            'Haben Sie als neue Organisation einen Service von Portbase angefordert? Sie erhalten eine E-Mail von Portbase, in der Sie Portbase mit IAMconnected verknüpfen können. Klicken Sie auf den Link in der E-Mail, um zu migrieren.',
        },
        step2: {
          title: 'Fertigstellung',
          introText: 'Sie sind dabei, sich mit der Organisation zu verbinden',
          secondIntroText:
            'Bitte beachten Sie, dass der Hauptadministrator dieser Organisation Ihre Anfrage zuerst genehmigen muss.',
        },
        couple: 'Verlinken Sie mich mit dieser Organisation',
        createOrganization: 'Ist Ihre Organisation noch nicht aufgeführt?',
        registerHbr: 'Zur Anmeldung Havenbedrijf Rotterdam?',
        registerHbrIntro:
          'Klicken Sie auf die Schaltfläche unten, um Ihre Organisation zu erstellen.',
      },
      choosePcsOrNew: {
        title: 'Willkommen bei IAMconnected',
        introText: `Möchten Sie Ihr Portbase PCS-Konto zu IAMconnected migrieren?`,
        buttons: {
          userHasPcs: 'Ja, Portbase PCS migrieren',
          userIsNew: 'Nein, weiter für ECT oder HbR',
          newOrg: 'Erstelle neue Organisation',
        },
      },
      userUsesPcs: {
        step1: {
          title: 'Melden Sich bei PCS an',
          introText:
            'Oeffnen Sie PCS in einem neuen Tab und melden Sie sich mit Ihren Daten an.',
          secondIntroText: `Links unter <strong>Meine Dienste</strong> finden Sie die Ueberschrift "Verknuepfen Sie Ihr PCS-Konto mit IAMconnected". Wenn Sie auf den Link klicken, werden Sie zu einer speziellen Anwendung weitergeleitet. Wenn dieser Link nicht vorhanden ist, ist Ihre Organisation noch nicht fuer IAMconnected bereit. Bitte wenden Sie sich hierzu an Ihren HauptHauptadministrator.`,
          buttonLabel: 'Oeffnen Sie PCS',
        },
        step2: {
          title: 'Melden Sie sich bei IAMconnected an',
          introText: `Sie werden an den Anschluss weitergeleitet. In dieser speziellen Anwendung koennen Sie Ihr PCS-Konto mit Ihrem neuen IAMconnected-Konto verknuepfen. Klicken Sie auf "Bei IAMconnected anmelden".`,
        },
        step3: {
          title: 'Verknuepfen Sie die Konten',
          introText: `Klicken Sie auf "Verknuepfen Sie Ihr PCS-Konto mit IAMconnected". Anschliessend werden die Konten erfolgreich verknuepft.`,
          secondIntroText: `Wenn Sie diesen Bildschirm sehen, können Sie den Assistenten schließen und der Bildschirm wird automatisch aktualisiert. Ihr Konto ist jetzt einsatzbereit.`,
        },
      },
    },
    organizationDetails: {
      title: 'Unternehmensdetails',
      warningMessageSection: {
        openRequestp1:
          'Sie haben eine offene Anfrage. Dieses muss abgeschlossen sein, bevor Sie wieder eine Anfrage vornehmen können. Gehe zu',
        openRequestp2: 'um den Status anzuzeigen.',
        openRequestAtDifferentOrganization:
          'Sie haben eine unvollendet offene Anfrage. Gehen Sie zum {{ name }} in der <a href="organizations/{{orgId}}">Unternehmensverwaltung</a> um diese zu einrichen oder annullieren.',
      },
      companyInfoSection: {
        title:
          'Neu bekommen Unternehmens-Details müssen vom der/die Bevollmächtigter Vertreter/-in validiert werden',
        button: 'Bekomm Unternehmens-Details',
        authSignatoryValidated:
          'Der/Die Bevollmächtigter Vertreter/-in ist validiert',
        authSignatoryNotValidated:
          'Der/Die Bevollmächtigter Vertreter/-in ist nicht validiert',
        userNotAllowed:
          'Sie haben nicht die erforderlichen Rechte um Unternehmensdaten zu ändern. <br> Wenden Sie sich an Ihren Hauptadministrator/-in <b>{{ name }}</b> wenn Daten geändert werden müssen.',
      },
      externalChangeForm: {
        tekst:
          'Andere Unternehmensdaten zum beispiel Kontakperson und MwSt.-Nummer können geändert werden durch dieses ',
        link: 'Änderungsformular',
      },
      newChangeRequestp1:
        'Ihre Änderungsanfrage wurde angefordert. Wenn der bevollmächtigter Vertreter die Anfrage genehmigt hat erhalten Sie einen Aktivierungscode Wählen Sie die Menueintrag ‘offene Anfrage’.',
      reprNotValidated:
        'Der bevollmächtigter Vertreter wurde nicht validiert. Gehen Sie zu den Kontaktdetails und wählen Sie einen bevollmächtigter Vertreter.',
      formDisabledMessage:
        'Hinweis: Die Unternehmensdetails können nur vom Hauptadministrator des Unternehmens bearbeitet werden. Der Hauptadministrator muss bei dem jeweiligen Unternehmen eingeloggt sein.',
      organizationInformation: {
        title: 'Unternehmensinfos',
      },
      organizationType: {
        introText: 'Wählen Sie hier Ihren Organisationstyp aus.',
      },
      businessInformation: {
        introText: 'Geben Sie hier Ihre Geschäftsinformationen ein.',
      },
      contactInformation: {
        title: 'Kontaktdetails',
        noData:
          'Bei der Handelskammer wurde kein bevollmächtigter Vertreter/-in gefunden, geben Sie diesen manuell ein. Der bevollmächtigter Vertreter/-in wird um zusätzliche Angaben des bevollmächtigter Vertreter/-in gebeten.',
        other: 'In der Handelskammer wurde kein Bevollmächtigter Vertreter/-in gefunden, bitte geben Sie dies manuell ein. Laden Sie zusätzliche Nachweise hoch, um zu beweisen, dass diese Person für Ihre Organisation zeichnungsberechtigt ist. Zum Beispiel ein Auszug aus der Handelskammer.'
      },
      identificationPreferences: {
        title: 'Externe Identifikation aktivieren',
        toggleLabel: 'Externe Identifikation aktivieren',
        introText:
          'Möchten Sie absolut sicher sein, dass Sie mit der richtigen Person des Kontoinhaber zu tun haben? Aktivieren Sie dann die externe Identifikation.',
        warningText:
          'Achtung: Sobald die externe Identifikation aktiviert ist, kann sie nicht mehr deaktiviert werden!',
        warningModal: {
          title: 'Externe Identifikation aktivieren',
          warningText:
            'Achtung: Sobald die externe Identifikation aktiviert ist, kann sie nicht mehr deaktiviert werden!',
          introText:
            'Möchten Sie absolut sicher sein, dass Sie mit der richtigen Person des Kontoinhaber zu tun haben? Aktivieren Sie dann die externe Identifikation.',
          content: '',
        },
      },
      authenticationPreferences: {
        title: 'Zusätzliche Sicherheit aktivieren?',
        introText:
          'Möchten Sie als Unternehmen absolut sicher sein, dass nur die richtigen Personen innerhalb Ihres Betriebs Zugang haben? Dann aktivieren Sie die Multifaktorauthentifizierung (MFA). Beim Einloggen auf IAMconnected wird der Nutzer dann gebeten, außer dem Passwort einen zusätzlichen Code einzugeben. Weitere Informationen finden Sie unter <a href="https://support.portbase.com/de/kennis/multi-factor-authentication-mfa/" target="_blank">Portbase-Supportseite.</a>',
        warningText:
          'Achtung: Wenn MFA einmal aktiviert wurde, können Sie es nicht mehr deaktivieren!',
        toggleLabel: 'MFA aktivieren',
        warningModal: {
          title: 'MFA aktivieren',
          warningText:
            'Achtung: Sobald MFA ausgewählt und dieses Formular anschließend aktualisiert wurde, kann dies nicht rückgängig gemacht werden.',
          introText:
            'Durch Aktivieren von MFA ist dies für alle Mitarbeiter Ihres Unternehmens aktiviert. Sie müssen innerhalb Ihres Unternehmens kommunizieren, dass ab jetzt zum Einloggen die MFA genutzt werden muss.',
          content:
            'Ihre Mitarbeiter werden gebeten, die MFA beim nächsten Login einzurichten.',
        },
      },
      attestationPreferences: {
        title: 'Aktivierung der periodische Validierung',
        introText:
          'Möchten Sie als Unternehmen, dass Ihr Mitarbeiter und seine Daten regelmäßig überprüft werden? Schalten Sie dann die periodische Validierung (Attestation) ein. Die zugrunde liegenden Anwendungen, die sicherstellen möchten, dass Ihre Mitarbeiter und ihre Daten immer noch auf dem neuesten Stand sind, müssen möglicherweise regelmäßig überprüft werden. Durch die periodische Validierung können Ihre Mitarbeiter auf vertrauliche Dienste und Informationen zugreifen (bleiben).',
        warningText:
          'Achtung! Sobald die perdiodische Validierung aktiviert ist, kann diese Funktionalität nicht mehr deaktiviert werden.',
        toggleLabel: 'Aktivierung der periodische Validierung',
        warningModal: {
          title: 'Aktivierung der periodische Validierung',
          warningText:
            'Achtung: Sobald die periodische Überprüfung aktiviert und dieses Formular anschließend aktualisiert wurde, ist dies irreversibel!',
          introText:
            'Durch Aktivierung der periodische Validierung wird der Hauptadministrator regelmäßig aufgefordert, die Mitarbeiter und ihre Daten zu validieren.',
          content:
            'Durch die periodische Überprüfung können Ihre Mitarbeiter auf vertrauliche Dienste und Informationen aus zugrunde liegenden Anwendungen zugreifen.',
        },
      },
      formLabels: {
        name: 'Name des Unternehmens',
        address: 'Adresse',
        zipCode: 'Postleitzahl',
        zip_code: 'Postleitzahl',
        city: 'Ort',
        country: 'Land',
        country_uncode: 'Land',
        cocId: 'Handelskammer-Nummer',
        coc_id: 'Handelskammer-Nummer',
        coc_number: 'Handelskammer-Nummer',
        duns: 'DUNS-Nummer',
        eoriNumber: 'EORI-Nummer',
        eoriEntry: 'EORI entry sequence nummer',
        eoriExit: 'EORI exit sequence nummer',
        eanCode: 'EAN-Code',
        portAuthorityId: 'Port Authority ID',
        port_authority_id: 'Port Authority ID',
        organizationAdministrator: 'Hauptadministrator',
        telephone_organizationAdministrator: 'Telefonnummer',
        email_organizationAdministrator: 'Email-Addresse Hauptadministrator',
        admin_name: 'Hauptadministrator',
        admin_phone: 'Telefonnummer',
        admin_email: 'Email-Addresse Hauptadministrator',
        phone: 'Telefon',
        email: 'Email-Addresse',
        authorizedSignatory: 'Bevollmächtigter Vertreter',
        repr_name: 'Bevollmächtigter Vertreter',
        mfaEnabled: 'MFA aktivieren',
        status: 'Status',
        type: 'Type',
        member_of: 'Nutzerrechte',
        choose: 'Wählen Sie ein Unternehmen',
        organizationType: 'Organisationstyp',
        organization_types: 'Organisationstyps',
        authorized_representative: 'Bevollmächtigter Vertreter',
        establishment: 'Niederlassung',
        telephone_authorized_representative:
          'Telefonnummer Bevollmächtigter Vertreter',
        email_authorized_representative:
          'Email-Addresse Bevollmächtigter Vertreter',
        repr_phone_number: 'Telefonnummer Bevollmächtigter Vertreter',
        repr_email_address: 'Email-Addresse Bevollmächtigter Vertreter',
        repr_phone: 'Telefonnummer Bevollmächtigter Vertreter',
        repr_email: 'Email-Addresse Bevollmächtigter Vertreter',
        rsin: 'RSINnummer',
        telephoneNumber: 'Telefonnummer',
        telephone: 'Telefonnummer',
        establishmentNumber: 'Standortnummer',
        est_id: 'Standortnummer',
        est_number: 'Standortnummer',
        date_of_birth: 'Geburtsdatum (DD-MM-YYYY)',
        repr_date_of_birth: 'Geburtsdatum (DD-MM-YYYY)',
        lastVerifiedDate: 'Letztes Mal bestätigt',
      },
      sectionOrganizationAdministrator: {
        noOrganizationAdministrator: 'Kein Hauptadministrator bekannt',
        changeOrganizationAdministrator: 'Hauptadministrator ändern',
        confirmChangeOrganizationAdministrator:
          'Die Änderung kann vom neuen Hauptadministrator nur nach Zustimmung des bevollmächtigter Vertreter aktiviert werden',
        noUsers: 'Es gibt keine Benutzer für diese Organisation',
      },
      sectionCompanyInfo: {
        title: 'Unternehmens-Details',
        loading: 'Einen Moment bitte, wir suche die Daten ab',
        errorTexts: {
          changeRequest: {
            unknownResource:
              'Die Handelskammer Nummer ist nicht korrekt, die Daten können nicht aktualisiert werden. <br>Wenden Sie sich unter +31 (0)88 625 25 34 an den Portbase Vertrieb um fortzusetzen.',
          },
          organization: {
            invalidResourceSelected:
              'Die Unternehmensdaten können nicht gefunden werden, die Daten können nicht aktualisiert werden. <br>Wenden Sie sich unter +31 (0)88 625 25 34 an den Portbase Vertrieb um fortzusetzen.',
          },
        },
        showMore: 'Weitere Daten',
        showLess: 'Weniger Daten',
        unknown: 'Unbekannt',
        companyContact: 'Kontakt',
        telephoneNumber: 'telefoonnummer',
        getOrgDataButton: 'Organisationsdaten abrufen',
        internationalResults: 'Ergebnisse gefunden',
        cocNotFound:
          'Die Organisationsdetails mit dieser Handelskammernummer wurden nicht gefunden. Überprüfen Sie die Eingabe von der Handelskammernummer. Wenn das Problem dadurch immer noch nicht behoben wird, wenden Sie sich unter +31 (0)88 625 25 34 an den Portbase Vertrieb um fortzusetzen.',
        dunsNotFound:
          'Die Organisationsdetails wurden nicht gefunden. Überprüfen Sie die Eingabe. Wenn das Problem dadurch immer noch nicht behoben wird, wenden Sie sich unter +31 (0)88 625 25 34 an den Portbase Vertrieb um fortzusetzen.',
        dunsDontKnow: 'Ich kenne meine DUNS-Nummer nicht',
        dunsDialog: {
          title: 'DUNS-Nummernsuche',
          description:
            'Füllen Sie alle unten stehenden Felder aus und drücken Sie auf Suchen, wenn Ihr Unternehmen nicht gefunden wird. Bitte wenden Sie sich an den Servicedesk.',
        },
        mainAdminTitle: 'Bitte beachten Sie:',
        mainAdminNotice: 'Mit der Beantragung werden Sie automatisch zum Hauptadministrator/-in dieser Organisation.',
        fileUpload: {
          title: 'Laden Sie den Nachweis der Zeichnungsberechtigung hoch (jpg, pdf, png)',
          drop: 'Ziehen Sie hier Ihre Datei(en) her',
          choose: 'Datei wählen',
        }
      },
      sectionContactDetails: {
        title: 'Kontaktdetails',
        authorizedrepresentative: 'Bevollmächtigter Vertreter',
        validateEmail:
          'Bitte bestätigen Sie Ihre E-Mail-Adresse, um fortzufahren',
        validateEmailButton: 'E-mail Adresse bestätigen',
      },
      sectionExtraInformation: {
        title: 'Zusatzinformation',
        rsin: 'RSIN nummer',
      },
      sectionSecurity: {
        title: 'Sicherheit',
        attestationFrequency:
          'Häufigkeit der periodische Validierungserinnerung',
        frequency: {
          oneMonth: '1 Monat',
          threeMonths: '3 Monate',
          halfYear: 'Halbjährlich',
        },
      },
      sectionReferenceData: {
        title: 'Referenzdaten',
      },
      sectionSaveInformation: 'Änderungsanforderung ist noch nicht eingereicht',
    },
    organizationTypes: {
      AGENT: 'Agentur',
      BARGE_OPERATOR: 'Binnenschifffahrtsunternehmen',
      EMPTY_DEPOT: 'Leercontainerdepot',
      FORWARDER: 'Spediteur',
      EXPORTER: 'Exporteur',
      IMPORTER: 'Importeur',
      INSPECTION_STATION: 'Kontrollstellen',
      SHIPBROKER: 'Schiffahrtsagentur, Reederei',
      SKIPPER: 'Schiffsführer',
      RAIL_OPERATOR: 'Bahnbetrieber, Eisenbahnverkehrsunternehmen',
      TERMINAL: 'Terminal',
      ROAD_HAULIER: 'Fuhrunternehmen',
      CUSTOMS: 'Zollamt',
      FOOD_HEALTH_SERVICE: 'Lebensmittel- und Gesundheitsdienst',
      PORT_AUTHORITY: 'Hafenbehörde',
      RAILWAY_AUTHORITY: 'Eisenbahnbehörde',
      IT_SERVICE_PROVIDER: 'IT Service provider',
    },
    organizationRoles: {
      ADMIN: 'Hauptadministrator',
      BACK_OFFICE: 'Back office',
      BARGE_OPERATOR: 'Barge operator',
      BARGE_TERMINAL_OPERATOR: 'Barge terminal operator',
      BARGE_VIEWER: 'Barge viewer',
      BOATMEN: 'Boatmen',
      CAPTAIN: 'Captain',
      CARGO_HANDLING_AGENT: 'Cargo handling agent',
      CARGO_TRACKER_ORG: 'Cargo tracker organization',
      CARRIER: 'Carrier',
      CONTAINER_LESSOR: 'Container lessor',
      CONTAINER_OPERATOR: 'Container operator',
      CUSTOMS: 'Customs',
      DEPOT_OPERATOR: 'Depot operator',
      DEVELOPER: 'Developer',
      FINANCIAL_DECLARANT: 'Financial declarant',
      FORMER_SHIP_OPERATOR: 'Former ship operator',
      FORWARDER: 'Forwarder',
      FUTURE_SHIP_OPERATOR: 'Future ship operator',
      HELP_DESK: 'Help desk',
      INSPECTION_LOCATION: 'Inspection location',
      INTEGRAL_PLANNER: 'Integral planner',
      MVL_DECLARANT: 'MVL declarant',
      ORG_ADMIN: 'Organization Hauptadministrator',
      PILOT: 'Pilot',
      PORT_AUTHORITY: 'Port authority',
      RAIL_OPERATOR: 'Rail operator',
      RAIL_TERMINAL_OPERATOR: 'Rail terminal operator',
      RAIL_VIEWER: 'Rail viewer',
      RAILWAY_AUTHORITY: 'Railway authority',
      REGISTRANT: 'Registrant',
      ROAD_HAULIER: 'Road haulier',
      ROAD_TERMINAL_OPERATOR: 'Road terminal operator',
      ROAD_VIEWER: 'Road viewer',
      SHIP_OPERATOR: 'Ship operator',
      TERMINAL_OPERATOR: 'Terminal operator',
      TOWAGE: 'Towage',
      TRACTION_SUPPLIER: 'Traction supplier',
      VIP_INSPECTION_REQUESTER: 'VIP inspection requestor',
      VIP_MVL_ADMIN: 'VIP/MVL Hauptadministrator',
      VWA: 'VWA',
      WASTE_COLLECTOR: 'Waste collector',
    },
    organizationsManagement: {
      title: 'Unternehmensverwaltung',
      tabs: {
        coupleWithOrganization: 'Mit einem Unternehmen verbinden',
        myOrganizations: 'Meine Organisationen',
        addOrganization: 'Neues Unternehmen hinzufügen',
        finishRequest: 'Komplette Registrierung',
      },
      dialog: {
        title: 'Trennen',
        content:
          'Ihr Konto wird für {{ orgName }} entfernt. Ihr Konto bleibt für die anderen Organisationen aktiv.',
        content_main: 'Ihr Konto wird entfernt.',
        content_main_multiple_orgs:
          'Ihr Konto wird für {{ orgName }} und die anderen verbundenen Organisationen entfernt.',
      },
      tooltip: {
        delete: 'Den Nutzer von dem Unternehmen entfernen.',
      },
      overview: {
        noDataToDisplay:
          'Sie sind derzeit keiner Organisation zugeordnet. Verwenden Sie die Schaltfläche unten, um eine Verbindung mit einer Organisation herzustellen.',
      },
    },
    organizationsCoupling: {
      title: 'Mit einem vorhandenen Unternehmen verbinden',
      content:
        'Linkanfrage für zusätzliche Organisation. Als Benutzer können Sie auch Arbeiten für eine andere Organisation als Ihre eigene ausführen. Dazu reichen Sie eine Verknüpfungsanfrage für eine weitere Organisation ein. Der Administrator der zusätzlichen Organisation muss diese Zuordnung genehmigen.',
      formLabels: {
        organizationName: 'Unternehmensname',
        organizationRoles: 'Organisationsrollen',
      },
      attestation: {
        status: 'Validiert',
        valid: 'Ihr Konto wurde vom Administrator validiert',
        invalid: 'Der Administrator der Organisation muss Ihr Konto validieren',
        validated_date: 'Letztes Mal bestätigt',
      },
    },
    openRequests: {
      title: 'Offene Anfrage',
      organization: 'Unternehmen',
      activationCode: 'Aktivierungscode',
      noOpenRequests: 'Derzeit sind keine Anfragen offen',
      verifyEmail: 'E-mail Adresse bestätigen',
      changeUserProfile: 'Änderungsantrag Profil',
      description: {
        verifyEmail: 'Es steht eine Anfrage zur Bestätigung Ihrer E-Mail Adresse aus.',
        changeUserProfile: 'Für Ihr Profil steht eine Änderungsanfrage aus.'
      },
      requestWithStatusNew: {
        p1: 'Sie haben eine unvollendet offene Anfrage. Gehen Sie zur',
        p2: 'um diese zu einrichen oder annullieren.',
      },
      cancelRequest:
        'enn die Registrierungsanfrage nicht mehr gewünscht wird, klicken Sie hier.',
      activate: 'aktivieren',
      newOrganization: 'neue Organisation',
      changeOrganizationDetails: 'Organisationsdetails ändern',
      changeOrganizationReferenceData: 'Organisationsreferenzdaten ändern',
      changeOrganizationRepresentative: 'bevollmächtigter Vertreter ändern',
      changeOrganizationAdmin: 'Hauptadministrator ändern',
      changeOrganizationType: 'Organisationstyp ändern',
      validateOrganizationDetails: 'Organisationsdetails validieren',
      refreshOrganizationDetails: 'Organisationsdetails aktualisieren',
      newService: 'Neue service',
      changeBusinessInformation: 'Geschäftsinformationen ändern',
      updateOrganizationDetails: 'Organisationsdetails ändern',
      sectionProcessInsights: {
        createOrg: {
          title: 'Status registrierung Organisation',
          step1: {
            title:'Identifizierung',
            content: 'Für Ihrer Änderungsantrag ist eine Identifizierung der/die bevollmächtiger Vertreter/-in notwendig. Der/die bevollmächtiger Vertreter/-in hat eine E-Mail mit Anweisungen erhalten.',
            button: 'Identifizierungs-E-Mail erneut senden'
          },
          step2: {
            title: 'Beurteilung',
            content: 'Portbase wird Ihrer Anfrage beurteilen.'
          },
          step3: {
            title: 'Genehmigen',
            content: 'Eine Einladung zur Vertragsunterzeichnung wurde per E-Mail an den bevollmächtigter Vertreter/-in versandt.',
            button: 'Einladungs-E-Mail erneut senden'
          }
        },
        changeOrgDetails: {
          title: 'Organisationsdaten ändern',
          step1: {
            title: 'Identifizierung',
            content:
              'Für Ihrer Änderungsantrag ist eine Identifizierung der/die bevollmächtiger Vertreter/-in notwendig. Der/die bevollmächtiger Vertreter/-in hat Anweisungen per E-Mail erhalten.',
          },
          step2: {
            title: 'Beurteilung',
            content:
              'Portbase wird Ihrer Anfrage beurteilen.',
          },
          step3: {
            title: 'Genehmigen',
            content: 'Eine Einladung zur Vertragsunterzeichnung wurde per E-Mail an den bevollmächtigter Vertreter/-in versandt.'
          },
          footer:
            'Weitere Informationen finden Sie auf <a target="_blank" href="https://support.portbase.com/de/iamconnected/">Support</a>;',
          sectionRequestDetails: {
            current: 'Aktuell',
            new: 'Neu',
          },
        }
      },
    },
    userManagement: {
      title: 'Nutzerrechteverwaltung',
      filter: 'Nutzer filtern',
      warningMessages: {
        emailNotVerified:
          'Dieser Benutzer hat seine E-Mail-Adresse noch nicht bestätigt. Der Benutzer kann seine E-Mail-Adresse über den Link in der von uns gesendeten E-Mail verifizieren. <br />Der Benutzer muss innerhalb von 24 Stunden auf den Bestätigungslink in der E-Mail klicken, andernfalls wird diese Änderungsanfrage storniert.',
        deleteUserAccount:
          'Möchten Sie diesen Benutzer wirklich löschen? Das Benutzerkonto wird vollständig gelöscht.<br />Infolgedessen kann das Konto nicht mehr für andere Organisationen verwendet werden.',
        decoupleUserAccount:
          'Möchten Sie diesen Benutzer wirklich aus Ihrer Organisation entfernen? Der Benutzer wird von Ihrer Organisation getrennt.',
      },
      periodicValidation: {
        selectEmployees: 'Wählen Mitarbeiter in der Liste',
        quarterLabel1: 'Januar - Marsch',
        quarterLabel2: 'April - Juni',
        quarterLabel3: 'Juli - September',
        quarterLabel4: 'Oktober - Dezember',
        quarter: ' Quartal ',
        history: {
          title: 'Vorherige Kontrollen',
          numberOfEmployees: '{{amount}} aus {{total}} Mitarbeiter ',
          numberOfEmployeesCompleted: '{{total}} Mitarbeiter ',
          new: 'Nicht erledigt',
          not_completed: 'Nicht erledigt',
          completed: 'Erledigt',
          review: 'Kontrolle',
          status: 'Status',
          number: 'Anzahl',
          validatedBy: 'Validierung durch',
        },
        completed: 'erledigt',
        lastLogin: 'de{{date}}',
        notificationBar: {
          title: 'Die Quartalskontrolle ist offen.',
          description: 'Es sind {{count}} Mitarbeiter zu validieren.',
          titleUsermanager: 'Die Quartalskontrolle ist fast erledigt.',
          descriptionUsermanager: 'Bitten Sie Ihren Hauptadministrator/-in, die Nutzerrechteverwalter zu validieren.',
          button: 'Start Kontrolle',
          descriptionOpen: 'Bitte validieren Sie alle Ihre Mitarbeiter zur Fertigstellung.',
          titleOpen: 'Sie haben eine laufende Quartalskontrolle',
          buttonOpen: 'Kontrolle fortsetzen',
        },
        feedback: {
          success: {
            title: '{{count}} Mitarbeiter validiert',
            singleTitle: 'Einen Mitarbeiter validiert',
          },
          alreadyCompleted: {
            title: 'Die Quartalskontrolle ist bereits erledigt',
            message: 'Ein anderer Verwalter ist Ihnen zuvorgekommen.',
          },
          userAdminRestriction: {
            title: 'Nur der/die Hauptadministrator/-in kann Nutzerrechteverwalter validieren',
            message: 'Als Nutzerrechteverwalter/-in können Sie andere Nutzerrechteverwalter nicht validieren. Bitten Sie Ihren Hauptadministrator/-in, Ihnen bei der Durchführung der Quartalskontrolle zu helfen.',
          },
          featureUnavailable: {
            title: 'Diese Funktionalität ist derzeit nicht verfügbar',
            message: 'Bitte versuchen Sie es später noch einmal.',
          },
        },
        dialog: {
          header: 'Alle Mitarbeiter validieren',
          content: 'Sie sind dabei, alle Mitarbeiter auf einmal zu validieren. Tun Sie dies nur, wenn Sie sicher sind, dass jeder Zugang behalten darf.',
          submit: 'Alle Mitarbeiter validieren'
        }
      },
      actions: {
        approve: 'Verbinden Sie den Nutzer mit dem Unternehmen',
        reject: 'Verweigern Sie dem Nutzer die Verbindung mit dem Unternehmen',
        suspend: 'Sperren Sie diesen Nutzer für das Unternehmen',
        attestation: 'Überprüfen Sie die Daten dieses Benutzers',
        unsuspend:
          'Stellen Sie die Rechte des Nutzers für das Unternehmen wieder her',
        delete: 'Lösen Sie diesen Nutzer von dem Unternehmen',
        usermanagers:
          'Erteile diesen Nutzer die Rechte zur Nutzerrechteverwaltung',
        removeUsermanagers:
          'Entziehen Sie dem Nutzer die Rechte zur Nutzerrechteverwaltung',
        resetMFA: 'Zurücksetzen der Multi-Faktor-Authentifizierung',
        searchEmployee: 'Mitarbeiter suchen',
        accessLevel: 'Zugangsebene',
        organizationAccess: 'Organisation zugang'
      },
      buttons: {
        makeUserManager: 'Benutzerrechte zulassen',
        suspend: 'Benutzer sperren',
        attestation: 'Überprüfung der Benutzer',
        delete: 'Benutzer entkoppeln',
        unsuspend: 'Benutzer nicht suspendieren',
        removeUserManager: 'Benutzerrechte nicht zulassen',
        downloadMemberOverview: 'Download Benutzer-/innen Übersicht',
        resetMFA: 'MFA zurücksetzen',
        validateEmployee: 'Mitarbeiter validieren',
        validateEmployeeSingle: 'Einen Mitarbeiter validieren',
        validateEmployeePlural: '{{count}} Mitarbeiter validieren',
        recover: 'Erholen'
      },
      tabs: {
        quarterlyReview: 'Quartalskontrollen'
      },
      tables: {
        firstName: 'Vorname',
        last_login: 'Zuletzt eingeloggt',
        lastName: 'Nachname',
        login: 'Nutzername',
        date_submitted: 'Datum der Übermittlung',
        date_approved: 'Datum der Genehmigung',
        actions: 'Aktionen',
        personalIdentification: 'Persönliche Identifikation',
        businessEmail: 'Geschäftliche-Email-Addresse',
        businessPhone: 'Geschäftstelefonnummer',
        roles: 'Rollen',
        successFullyIdentified: 'Der Benutzer wurde erfolgreich identifiziert',
        notSuccessFullyIdentified:
          'Der Benutzer wurde nicht erfolgreich identifiziert',
        emailValidated: 'Geschäftliche-Email-Addresse ist validiert',
        emailNotValidated: 'Geschäftliche-Email-Addresse ist nicht validiert',
        userNotIdentified:
          'Achtung! dieser Benutzer kann noch nicht akzeptiert werden, da er /sie sich noch nicht identifiziert hat.',
        expiry: 'Enddatum (optional)',
        personalInformation: 'Persönliche Angaben'
      },
    },
    serviceManagement: {
      title: 'Diensteverwaltung',
      data: 'Daten',
      example: 'Beispiel',
      logo: 'Logo',
      serviceOverview: {
        title: 'Anwendungen, von meiner Organisation angeboten',
        newService: 'Neue Anwendung anbieten',
      },
      imageUpload: {
        title:
          '<strong>Logo hochladen</strong><br />Maximal 80x80 Pixel und JPG- oder PNG-Format.',
        delete: 'Logo löschen',
        maxImageSize: 'Maximale Bildgröße 1 MB',
        fileType: 'Es sind nur Bilder erlaubt (JPG | PNG)',
        maxImageResolution: 'Maximale Bildgröße 80 * 80px',
      },
      formLabels: {
        name: 'Name',
        title: 'Titel',
        logo: 'Logo',
        redirect_uri: 'Redirect uri',
        description: 'Beschreibung',
        link_url: 'Link URL',
        window_target: 'Window Target',
        client_id: 'Client ID',
      },
      success:
        'Erfolg! Bei Portbase gibt es eine offene Anfrage für diesen neuen Dienst',
      openRequest:
        'Je hebt al een openverzoek, deze dient eerst afgerond te worden voordat je deze service kan wijzigen.',
      tabData: 'Daten',
      tabAccessControl: {
        title: 'Zugangskontrolle',
        accessToMyService: 'Zugang zu meiner Anwendung',
        accessToMyServiceSubtitle:
          'Die Liste der unten aufgeführten Unternehmen hat Zugriff auf Ihre Anwendung.',
        buttonRevokeAccess: 'Zugriff verweigern',
        giveAccessToMyService: 'ein Unternehmen Zugang geben zu meinem Service',
        giveAccessToMyServiceSubtitle:
          'Suchen Sie nach einem Unternehmen, um Zugriff auf Ihre Anwendung zu erhalten.',
        searchCompanyInputFieldHint: 'Suchen Sie nach einem Unternehmen',
        searchResults: 'Suchergebnisse:',
        searchNoResults: 'Keine Unternehmen gefunden',
        buttonGiveAccess: 'Zugang gewähren',
      },
    },
    changeOrganizationAdministrator: {
      title: 'Hauptadministrator ändern',
      introText: {
        firstParagraph:
          'Um den Hauptadministrator zu ändern, müssen Sie zuerst einen anderen Hauptadministrator auswählen und absenden.',
        secondParagraph: 'Hinweis: Sie muessen auch das',
        changeFormLinkUrl: 'https://www.portbase.com/de/form/formular-aendern/',
        changeFormLinkText: 'Portbase-Änderungsformular ausfüllen.',
        thirdParagraph:
          'Die Änderung wird erst nach Genehmigung durch unsere Abteilung Sales durchgeführt.',
      },
      form: {
        title: 'Hauptadministrator ändern',
        introText:
          'Wählen Sie einen neuen Hauptadministrator aus der folgenden Liste.',
        autoCompletePlaceholder: 'Einen Mitarbeiter finden',
        submitButton: 'Anfrage senden',
      },
      confirmNewOrganizationAdministrator: {
        firstParagraph:
          'Sie sind dabei, den Hauptadministrator des Unternehmens zu ändern',
        secondParagraph:
          'Sind Sie sicher, dass Sie den Hauptadministrator ändern möchten?',
      },
    },
    newOrganizationAdministrator: {
      title: 'Neuer Hauptadministrator',
      tooltip: 'Ausstehende Genehmigung durch Portbase',
    },
    news: {
      title: 'Nachrichten',
      subTitle: 'Lesen Sie hier alles über die jüngsten Entwicklungen',
      items: [
        {
          title: 'Neue Funktionalität: Dieser Newsfeed',
          details:
            'Ankündigungen und aktuelle Entwicklungen werden hier vorgestellt',
        },
      ],
    },
    feedback: {
      title: 'Feedback',
      button: 'Contact customer service',
      description1:
        'Als Kunde von Portbase können Sie 24/7 Kontakt aufnehmen mit unserem Customer Service. Unsere Mitarbeiter können auf Niederländisch, Englisch und Deutsch Ihre Fragen beantworten.',
      description2:
        'Der Customer Service von Portbase ist Ihre zentrale Anlaufstelle für alle Ihre Fragen über die Dienste. Für die vollständige Bearbeitung von Fragen und Mitteilungen können Sie sich an unser Team aus geschulten und spezialisierten Mitarbeitern wenden. Um eine effiziente Abfertigung garantieren zu können, können Sie sich mit allen Fragen direkt an uns wenden und wir versuchen Ihre Hilfeanfrage sofort zu bearbeiten.',
      allowContact: 'IAMconnected darf mich kontaktieren.',
      success: 'Erfolg, Ihre Hilfeanfrage wird vom Kundenservice bearbeitet!',
    },
    changeBusinessInformationRequestTypes: {
      change_business_information: 'Änderung der Geschäftsinformationen',
      change_user_profile: 'Änderung der Benutzerprofil',
    },
    verifyEmailPage: {
      title: 'E-Mail-Verifizierung',
      success: 'Vielen Dank, Ihre E-Mail-Adresse wurde bestätigt.',
      error: 'Beim Bestätigen der E-Mail-Adresse ist ein Fehler aufgetreten.',
      errorEmailAlreadyVerified:
        'Die E-Mail-Adresse wurde bereits verifiziert, Sie müssen nichts weiter unternehmen.',
      errorLoggedin:
        'Der Verifizierungslink ist abgelaufen, fordern Sie eine weitere Verifizierungs-E-Mail an.',
      errorNotLoggedin:
        'Der Verifizierungslink ist abgelaufen, melden Sie sich an und fordern Sie erneut eine Verifizierungs-E-Mail an.',
      errorLoggedinButton: 'Bestätigungsmail erneut senden',
    },
    migratingBusinessInformation: {
      sectionChangeEmail: {
        title: 'Daten ändern',
        description:
          'Geben Sie unten Ihre neuen Daten ein. Eine Änderungsanfrage wird an Ihren Hauptadministrator gesendet. Nach Genehmigung durch Ihren Hauptadministrator sind die Änderungen aktiv.',
        emailField: 'Ihre geschäftliche E-Mail-Adresse',
        phoneField: 'Ihre geschäftliche Telefonnummer',
      },
      sectionEmailSend: {
        title: 'Eine Validierungs-E-Mail wurde gesendet',
        description:
          'Sie haben einen Validierungslink erhalten am {{businessEmail}} Klicken Sie auf den Link in der E-Mail, um Ihre E-Mail-Adresse zu validieren. Falls Sie diese E-Mail nicht erhalten haben, überprüfen Sie Ihren Spam-Ordner.',
        button: 'Validierungslink erneut senden',
        cancel: 'Falsche E-Mail Adresse? Klicken Sie hier'
      },
      sectionVerifyEmail: {
        title: 'Validierung Ihre E-Mail-Adresse',
        description:
          'Um die Sicherheit von IAMconnected zu erhöhen, müssen alle Konten eine eindeutige E-Mail-Adresse haben. Dies ist die geschäftliche E-Mail-Adresse, die mit Ihrer Organisation verbunden ist.',
        question: 'Welche E-Mail-Adresse muss mit Ihrem Konto verbunden werden?',
        button: 'Validierung',
        attention: 'Von Gruppen-E-Mail-Adressen wird dringend abgeraten. Diese E-Mail-Adresse wird verwendet, um Änderungen in Ihrem Konto zu ermöglichen.'
      },
    },
    pageAcceptTermsAndConditions: {
      registration: {
        title: 'Registrierungsanfrage für',
        subTitle: 'Wir haben eine Registrierungsanfrage von ',
        subTitle2: 'Diese Registrierungsanfrage wird nicht bearbeitet sonst Genehmigung und Unterzeichnung durch Sie als bevollmächtigten Vertreter/-in dieser Organisation.'

      },
      change: {
        title: 'Änderungsanforderung von',
        subTitle: 'Wir haben eine Änderungsanfrage von ',
        subTitle2: 'Diese Änderungsanfrage wird nicht bearbeitet sonst Genehmigung und Unterzeichnung durch Sie als bevollmächtigten Vertreter/-in dieser Organisation.'
      },
      error: {
        notFound: 'Dieser Link ist nicht mehr gültig. Die Anfrage wurde möglicherweise gelöscht oder bereits akzeptiert.',
      },
      supportPage: 'Weitere Informationen finden Sie auf unseren <a target="_blank" href="https://support.portbase.com/de/iamconnected/">Support-Seiten</a>',
      success: 'Ihre Anfrage wurde erfolgreich abgeschlossen, Sie erhalten eine Bestätigungs-E-Mail.',
      table: {
        header: {
          col1: 'Alte Daten',
          col2: 'Neue Daten',
        }
      },
      termsAndConditions: {
        authorizedRepr: 'Ich bin der/die <a href="https://support.portbase.com/de/iamconnected/#zeichnungs-befugter" class="text-blue-A100" target="_blank">bevollmächtigter Vertreter/-in</a> für Organisation ',
        GeneralTermsAndConditions: 'Ich stimme den <a href="https://www.portbase.com/de/allgemeine-geschaeftsbedingungen/" target="_blank" class="text-blue-A100">Allgemeine Geschäftsbedingungen</a> zu',
        PatricipantConditions: 'Ich stimme den <a href="https://www.portbase.com/wp-content/uploads/2023/05/1205-Teilnahmebedingungen-IAMconnected-3.pdf" target="_blank" class="text-blue-A100">Teilnahmebedingungen</a> zu',
        mainAdministrator: ' ist <a href="https://support.portbase.com/de/iamconnected/#hauptverwalter" target="_blank" class="text-blue-A100">Hauptadministrator/-in</a> für meine Organisation',
        acceptThisChange: 'Ich stimme dieser Anfrage zu',
        acceptAll: 'Sie müssen alle Bedingungen akzeptieren',
        okeAndSend: 'Genehmigen'
      }
    }
  };
}
