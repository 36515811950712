<div *ngIf="loading">
  <iam-skeleton-loader type="field"></iam-skeleton-loader>
</div>
<mat-form-field [ngClass]="!loading ? 'visible' : 'hidden'" *ngIf="dataList?.length > 0" [appearance]="appearance" class="\">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <mat-select [id]="formControlName" [formControl]="control" [placeholder]="placeholder" [required]="required"
    [attr.data-test]="'select-' + formControlName" [multiple]="multiple" [compareWith]="
      compareFunction ? compareFunction : compareControlWithDataList
    " (selectionChange)="selectionChanges($event)">
    <mat-select-trigger *ngIf="chipsOutput">
      <mat-chip-listbox data-test="chips">
        <mat-chip-option *ngFor="let item of control.value" [removable]="removableChips" (removed)="removeItem(item)"
          [disabled]="!removableChips" [attr.data-test]="'option-' + (getItemNameById(item) | async)" [matTooltip]="
            showTooltip ? (dataList | getTooltipTranslationContent: item) : ''
          " [selected]="(dataList | showWarning: item: dataValue)"
          [color]="(dataList | showWarning: item: dataValue) ? 'warn' : ''">
          {{ getItemNameById(item) | async }}

          <button matChipRemove *ngIf="removableChips">
            <i  class="fa-light fa-xmark text-pb-grey-900 block"></i>
          </button>
        </mat-chip-option>
      </mat-chip-listbox>
    </mat-select-trigger>
    <mat-option *ngFor="let data of dataList" [value]="data[dataValue]" [attr.data-test]="'option-' + data[dataLabel]"
      [ngStyle]="{ display: data['active'] === false ? 'none' : 'flex' }">
      <div class="flex flex-col w-full">
        <span [innerHtml]="data[dataLabel] "></span>
        <i *ngIf="
          showTooltip &&
          (dataList | getTooltipTranslationContent: data[dataValue])
        "  [matTooltip]="dataList | getTooltipTranslationContent: data[dataValue]" class="ml-3 fa-light fa-circle-info">
      </i>
      </div>
    </mat-option>
  </mat-select>
  <mat-error>{{ error$ | async }}</mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>

<!-- If no list is provided, only show the chips-->
<mat-form-field [ngClass]="!loading ? 'visible' : 'hidden'" *ngIf="dataList?.length === 0 && control.value?.length > 0" [appearance]="appearance" class="select-field">
  <mat-chip-listbox #chipList [formControl]="control" [placeholder]="placeholder">
    <mat-chip-option *ngFor="let item of control.value" [removable]="removableChips" (removed)="removeItem(item)"
      [disabled]="!removableChips" [attr.data-test]="'option-' + (getItemNameById(item) | async)">
      {{ getItemNameById(item) | async }}
      <i matChipRemove *ngIf="removableChips" class="fa-light fa-xmark ml-2"></i>
    </mat-chip-option>
  </mat-chip-listbox>
</mat-form-field>
