import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IamRestService } from 'projects/iam/src/app/core/services/iam-rest.service';

// RXJS
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import {
  OrganizationRequestSearch,
  OrganizationRequestSearchResults,
} from '../../../models/organization/organization-request-search-results-interface';
import { ServiceMgtRequest } from '../../../models/servicemgt/servicemgt-request.interface';
import {
  AcceptTermsChangeRequest,
  ActivationCodeRequest,
  OrganizationRequest,
  PutOrganizationRequest,
} from '../../../models/organization/organization-request.interface';
import { UserBusinessProfile } from '../../../models/user/user-business-profile.interface';
import { BusinessInformationChangeRequest } from '../../../models/change-requests/business-information-change-request.interface';
import { OpenRequestTypes } from '../../open-requests/models/open-requests/open-request-types.model';
import { TermsAndConditions } from '../../home/models/terms-and-conditions.interface';

@Injectable({
  providedIn: 'root',
})
export class ChangeRequestsService extends IamRestService {
  constructor(private _http: HttpClient, private _store: Store) {
    super(_http, _store);
  }

  verifyEmail(code: string): Observable<void> {
    const url = `${this.baseUrl}/change_requests?q=${code}`;
    return this.post(url, {});
  }

  getChangeRequests(): Observable<OrganizationRequest> {
    const url = `${this.baseUrl}/change_requests/:userId`;
    return this.get(url);
  }

  getChangeRequestByCode(q: string): Observable<AcceptTermsChangeRequest> {
    const url = `${this.baseUrl}/change_requests?q=${q}`;
    return this.get(url);
  }

  getBusinessInformationChangeRequest(): Observable<
    BusinessInformationChangeRequest[]
  > {
    const url = `${this.baseUrl}/change_requests/:userId/change_business_information/:organizationId`;
    return this.get(url);
  }

  acceptChangeRequest(
    req_owner_id: string,
    req_type: OpenRequestTypes
  ): Observable<unknown> {
    const url = `${this.baseUrl}/change_requests/:userId/${req_type}/:organizationId/${req_owner_id}`;
    return this.put(url, {});
  }

  rejectChangeRequest(
    req_owner_id: string,
    req_type: OpenRequestTypes
  ): Observable<unknown> {
    const url = `${this.baseUrl}/change_requests/:userId/${req_type}/:organizationId/${req_owner_id}`;
    return this.delete(url);
  }

  searchOrganizationRequest({
    name,
    country_uncode,
    streetname,
    cityname,
  }: OrganizationRequestSearch): Observable<
    OrganizationRequestSearchResults[]
  > {
    const url = `${this.baseUrl}/change_requests/:userId/companies`;

    return this.formatUrl(url).pipe(
      mergeMap(url =>
        this._http.get<OrganizationRequestSearchResults[]>(url, {
          params: {
            name,
            country_uncode,
            streetname,
            cityname,
          },
        })
      )
    );
  }

  cancelChangeRequests(): Observable<unknown> {
    const url = `${this.baseUrl}/change_requests/:userId`;
    return this.delete(url);
  }

  newChangeRequests(
    cocData:
      | Partial<OrganizationRequest>
      | ServiceMgtRequest
      | UserBusinessProfile
      | {},
    requestType = OpenRequestTypes.NEW_ORGANIZATION,
    organizationId?: string
  ): Observable<{id?: string}> {
    let url = `${this.baseUrl}/change_requests/:userId/${requestType}`;
    if (organizationId) {
      url = `${url}/${organizationId}`;
    }
    return this.post(url, cocData);
  }

  updateOrganizationRequests(
    reprData: Partial<PutOrganizationRequest>,
    requestType = OpenRequestTypes.NEW_ORGANIZATION
  ): Observable<Partial<OrganizationRequest>> {
    const url = `${this.baseUrl}/change_requests/:userId/${requestType}`;
    return this.put(url, reprData);
  }

  submitActivationCode(
    activationCodeData: ActivationCodeRequest
  ): Observable<void> {
    const url = `${this.baseUrl}/change_requests/:userId/activate`;
    return this.put(url, activationCodeData);
  }

  resendEmailVerification(): Observable<void> {
    const url = `${this.baseUrl}/change_requests/:userId/${OpenRequestTypes.RESEND_EMAIL_VERIFICATION}`;
    return this.put(url, {});
  }

  resendIdentificationInvite(): Observable<void> {
    const url = `${this.baseUrl}/change_requests/:userId/resend_identification_invite`;
    return this.put(url, {});
  }

  resendActivationlink(): Observable<void> {
    const url = `${this.baseUrl}/change_requests/:userId/resend_activation_link`;
    return this.put(url, {});
  }

  acceptTermsAndConditions(code: string, termsAndConditions: TermsAndConditions): Observable<void> {
    const url = `${this.baseUrl}/change_requests`;
    return this.put(url, {code, ...termsAndConditions});
  }

  changeOrganizationUpdate(data: unknown, changeRequestId: string): Observable<unknown>{
    const url = `${this.baseUrl}/change_requests/:userId/change_organization/${changeRequestId}`;
    return this.put(url, data);
  }
}
